import React, { useEffect, useState } from 'react';
import './ToolMain.css';
import linkedInLogo from "../../Assets/WebToolAssets/linkedin.png";
import xLogo from "../../Assets/WebToolAssets/x.png";
import mediumLogo from "../../Assets/WebToolAssets/medium.png";
import redditLogo from "../../Assets/WebToolAssets/reddit.png";
import arrowLeftIcon from "../../Assets/WebToolAssets/arrow-left.png";
import copyIcon from "../../Assets/WebToolAssets/copy.png";
import shareIcon from "../../Assets/WebToolAssets/share-network.png";
import Notification from './OLD-Notification';

function ToolMain() {
    const wordCounts = [
        { label: 'Less than 100', value: 50 },
        { label: '100', value: 100 },
        { label: '200', value: 200 },
        { label: '300', value: 300 },
        { label: '400', value: 400 },
        { label: '500', value: 500 },
        { label: '600', value: 600 },
        { label: '700', value: 700 },
        { label: '800', value: 800 },
        { label: '900', value: 900 },
        { label: '1000', value: 1000 },
        { label: '1250', value: 1250 },
        { label: '1500', value: 1500 },
        { label: '1750', value: 1750 },
        { label: '2000', value: 2000 },
        { label: 'More than 2000', value: 2500 }
    ];

    const [inputText, setInputText] = useState(''); // State for the user's initial input
    const [streamedText, setStreamedText] = useState(''); // State for the streamed text
    const [isStreaming, setIsStreaming] = useState(false); // Whether we're in streaming mode
    const [isResult, setIsResult] = useState(false); // Whether we're in result mode
    const [title, setTitle] = useState("What do you want this post to be about?"); // State for the title
    const [currentWordCount, setCurrentWordCount] = useState(wordCounts[3]); // Word count from slider
    const [notification, setNotification] = useState({ message: '', type: '', visible: false }); // State for notification

    // Check if Web Share API is supported
    const [isClipboardSupported, setIsClipboardSupported] = useState(false);
    const [isShareSupported, setIsShareSupported] = useState(false);

    useEffect(() => {
        // Check if Clipboard API is supported
        if (navigator.clipboard) {
            setIsClipboardSupported(true);
        }

        // Check if Web Share API is supported
        if (navigator.share) {
            setIsShareSupported(true);
        }
    }, []);

    // Function to show notification
    const showNotification = (message, type) => {
        setNotification({ message, type, visible: true });

        // Hide notification after 3 seconds
        setTimeout(() => {
            setNotification({ ...notification, visible: false });
        }, 3000);
    };


    // This function will handle the streaming and pass the necessary data
    async function streamApiResponse(postType) {
        const textView = document.getElementById("text-view");

        try {
            setIsStreaming(true); // Set streaming state
            setTitle("Loading..."); // Change title

            const response = await fetch(`https://streaming-services-fxwoayzlaq-uc.a.run.app/utility/web_tool_prompt`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    text: inputText,   // User input
                    type: postType,    // Type based on button pressed
                    length: currentWordCount.label // Use the word count from the slider
                })
            });

            if (!response.ok || !response.body) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }

            const reader = response.body.getReader();
            const decoder = new TextDecoder();


            while (true) {
                const { done, value } = await reader.read();
                if (done) {
                    break; // Exit when the streaming is complete
                }

                const chunk = decoder.decode(value, { stream: true });
                setStreamedText(prevText => prevText + chunk); // Update the streamedText state progressively
            }

            setTitle("Here is the result! Edit and share."); // Change title
            setIsResult(true);

        } catch (error) {
            console.error('Error streaming the response:', error);
            setIsResult(false);
            showNotification('Failed to generate. Try again.', 'error');
            textView.value = 'Error streaming the response!';
        } finally {
            setTitle("What do you want this post to be about?");
            setIsStreaming(false); // Reset streaming state
        }
    }

    // Function to handle back button, which restores the initial input
    function handleBack() {
        setStreamedText('');
        setTitle("What do you want this post to be about?"); // Reset title
        setIsResult(false);
    }

    // Function to handle slider change
    const handleSliderChange = (e) => {
        const index = e.target.value;
        setCurrentWordCount(wordCounts[index]);
    };

    // Function to copy text to clipboard
    const handleCopy = async () => {
        try {
            await navigator.clipboard.writeText(streamedText);
            showNotification('Text copied to clipboard!', 'success');
        } catch (err) {
            console.error('Failed to copy text: ', err);
            showNotification('Failed to copy text.', 'error');
        }
    };

    // Function to share text using Web Share API
    const handleShare = async () => {
        try {
            await navigator.share({
                title: 'Content of the draft',
                text: streamedText,
                url: window.location.href, // Optionally share the current page URL
            });
            showNotification('Shared successfully!', 'success');
        } catch (err) {
            console.error('Error sharing: ', err);
            // showNotification('Failed to share.', 'error');
        }
    };

    return (
        <div className="center-aligned-container">
            <h1 className='container-title'>{title}</h1>

            {/* Notification component */}
            <Notification message={notification.message} type={notification.type} visible={notification.visible} />

            <textarea
                id="text-view"
                className="text-view"
                placeholder="Enter your text here"
                value={streamedText ? streamedText : inputText} // Show streamed text if available, otherwise show initial input
                onChange={(e) => isResult ? setStreamedText(e.target.value) : setInputText(e.target.value)} // Update state on input change
                disabled={isStreaming} // Disable input during streaming
            ></textarea>

            {/* Word count slider */}
            {!isResult && !isStreaming && (
                <div className='input-range-container'>
                    <h3>Select Word Count</h3>
                    <input
                        type="range"
                        min="0"
                        max={wordCounts.length - 1} // Length of the array minus 1
                        step="1"
                        value={wordCounts.findIndex(wc => wc.value === currentWordCount.value)} // Find index based on value
                        onChange={handleSliderChange} // Handle the change in slider
                    />
                    <p>Selected Word Count: <strong>{currentWordCount.label}</strong></p>
                </div>
            )}

            <div className='buttons-container'>
                {isResult && (
                    <>
                        <div className='action-button-container'>
                            <img className='action-button-image' src={arrowLeftIcon} alt="Back button icon" />
                            <button className='action-button' onClick={handleBack}>Back</button>
                        </div>
                        {isClipboardSupported && (
                            <div className='action-button-container'>
                                <img className='action-button-image' src={copyIcon} alt="Copy Icon" />
                                <button className='action-button' onClick={handleCopy}>Copy</button>
                            </div>
                        )}
                        {isShareSupported && (
                            <div className='action-button-container'>
                                <img className='action-button-image' src={shareIcon} alt="Share Icon" />
                                <button className='action-button' onClick={handleShare}>Share</button>
                            </div>
                        )}
                    </>
                )}

                {!isResult && !isStreaming && (
                    <>
                        <div className='action-button-container linkedin-button'>
                            <img className='action-button-image' src={linkedInLogo} alt="LinkedIn logo" />
                            <button className='action-button' onClick={() => streamApiResponse('LinkedIn Post')}>LinkedIn Post</button>
                        </div>
                        <div className='action-button-container x-button'>
                            <img className='action-button-image' src={xLogo} alt="X logo" />
                            <button className='action-button' onClick={() => streamApiResponse('Tweet')}>Tweet Post</button>
                        </div>
                        <div className='action-button-container medium-button'>
                            <img className='action-button-image' src={mediumLogo} alt="Medium logo" />
                            <button className='action-button' onClick={() => streamApiResponse('Blog Post')}>Blog Post</button>
                        </div>
                        <div className='action-button-container reddit-button'>
                            <img className='action-button-image' src={redditLogo} alt="Reddit logo" />
                            <button className='action-button' onClick={() => streamApiResponse('Reddit Post')}>Reddit Post</button>
                        </div>
                    </>
                )}
            </div>
        </div>
    );
}

export default ToolMain;
