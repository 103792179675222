import React from 'react';
import './OLD-Notification.css'; // Separate CSS file for notification styling

function Notification({ message, type, visible }) {
    if (!visible) return null; // Do not render if not visible

    return (
        <div className={`notification ${type}`}>
            {message}
        </div>
    );
}

export default Notification;