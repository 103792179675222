import React from 'react';
import { useNavigate } from 'react-router-dom';
import './Main.css';

function Main() {
    const navigate = useNavigate();

    return (
        <div className="container">
            <h1>My journey</h1>
            <div className="links">
                {/* <button onClick={() => navigate('/childhood')}>Childhood</button> */}
                <button onClick={() => navigate('/financial')}>Financial Dashboard</button>
                <button onClick={() => navigate('/education')}>Education</button>
                {/* <button onClick={() => navigate('/work')}>Work</button> */}
                <button onClick={() => navigate('/hobbies')}>Hobbies</button>
                {/* <button onClick={() => navigate('/mission')}>Mission</button> */}
                <button onClick={() => navigate('/contacts')}>Contacts</button>
                {/* <button onClick={() => navigate('/sketchfone')}>Sketchfone</button> */}
                {/* <button onClick={() => navigate('/writter-tool')}>Writter Tool</button> */}
            </div>
        </div>
    );
}

export default Main;